<template>
  <div v-show="loginStatus" class="login_content">
    <div class="login_banner">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-07-23/15/yuelvhuiW3VR6db0OH1595489947.png"
        alt
      >
    </div>
    <van-form class="login_box">
      <!-- <van-field
        v-model="phone_num"
        label="+86"
        left-icon="arrow-down"
        size="large"
        autofocus="true"
        center
        placeholder="请输入手机号码"
      />
      <van-field
        v-model="phone_code"
        maxlength="6"
        input-align="center"
        center
        clearable
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            color="linear-gradient(to right, #FF894F, #FF6863)"
            @click="vCodeBtn"
            :disabled="isCodeBtn"
          >{{ innerText }}</van-button>
        </template>
      </van-field>-->
      <!-- 手机+验证码 -->
      <div class="put_box">
        <div class="input_box">
          <span class="icon_int">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-08-10/09/yuelvhui6sroFnej2p1597023025.png"
            >
          </span>
          <van-field
            v-model="phone_num"
            size="large"
            autofocus="true"
            center
            placeholder="请输入手机号码"
          />
        </div>
        <div class="input_box">
          <span class="icon_int">
            <img
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-08-10/09/yuelvhuiAb5j3L7Ny71597023484.png"
            >
          </span>
          <van-field
            v-model="phone_code"
            maxlength="6"
            center
            clearable
            placeholder="请输入短信验证码"
          />
          <button class="vCodeBtn" :disabled="isCodeBtn" @click.stop="vCodeBtn">
            {{ innerText }}
          </button>
        </div>
      </div>
      <div style="margin-top: 40px">
        <van-button
          block
          round
          color="#F7263C"
          @click="onSublime"
        >登录
        </van-button>
      </div>
    </van-form>
    <div id="c1" />
    <!-- <div class="brew_box" v-if="isDingxiang"></div> -->
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Button, Field, Form, Toast } from 'vant'

Vue.use(Form).use(Field).use(Button).use(Toast)
import { setAnalytics } from '@/utils/headerInfo'
import {
  sendNewByWeChat,
  isCheckUserRegister,
  getWxAppId,
  getCodeByOpenid,
  getWapTokenByUnionid,
  getEquityType
} from '@/services/userApi'
import { isWeChat } from '@/utils/common/validate'

export default {
  data() {
    return {
      loginStatus: 0,
      isDingxiang: false, // 顶象模拟蒙层
      phone_num: '', // 手机号
      phone_code: '', // 验证码
      areaCode: '86', // 区域码
      code_num: '', // 邀请码
      openId: '', // 微信静默授权openid
      // 获取验证码
      isCodeBtn: false,
      wait: 59, // 倒计时
      innerText: '获取验证码',
      myCaptcha: '', // 顶象验证
      myCaptchaToken: '', // 滑块token
      codeNumber: '0' // 邀请码
    }
  },
  created() {
    // this.codeNumber = this.$route.query.codeNumber || "";
    // let  backUrl =   _self.$route.query.backUrl
    const backUrl = decodeURIComponent(
      decodeURIComponent(this.GetUrlParam('backUrl'))
    )
    this.codeNumber = this.GetUrlParam2(backUrl, 'codeNumber')
    let redirect = ''
    if (this.$route.query.redirect) {
      redirect = this.$route.query.redirect
    }
    // this.wxlogin(redirect)
    if (isWeChat()) {
      this.wxlogin(redirect) // 在微信中打开
    } else {
      this.loginStatus = 1
      Toast('请在微信浏览器中打开,否则会影响你的使用体验')
    }
  },
  mounted() {
    const _self = this
    // 实例化顶象无感验证
    // eslint-disable-next-line no-undef
    _self.myCaptcha = _dx.Captcha(document.getElementById('c1'), {
      appId: '6738137ae0a10824eb3b5f775a1bf7fb',
      success: function(token) {
        _self.myCaptchaToken = token
        _self.lsendNewByWeChat()
        _self.myCaptcha.hide()
        _self.isDingxiang = false
      },
      fail: function(err) {
        console.log(err)
      },
      language: localStorage.getItem('language'),
      style: 'popup',
      inlineFloatPosition: 'up'
    })
  },
  methods: {
    // 验证码事件绑定
    vCodeBtn: function() {
      const _self = this
      window.scroll(0, 0)
      const _phone = _self.phone_num
      const phoneReg = /^1\d{10}$/
      if (!_phone || !phoneReg.test(_phone)) {
        Toast('请输入有效的手机号码！')
        return false
      } else {
        _self.lisCheckUserRegister()
      }
    },
    // 验证码倒计时
    sTime() {
      const _self = this
      if (_self.wait == 0) {
        _self.innerText = '获取验证码'
        _self.myCaptchaToken = ''
        _self.myCaptcha.reload()
        _self.wait = 59
        _self.isCodeBtn = false
      } else {
        _self.innerText = _self.wait + 's后再发'
        _self.isCodeBtn = true
        _self.wait--
        setTimeout(function() {
          _self.sTime()
        }, 1000)
      }
    },
    // 获取验证码请求
    lsendNewByWeChat() {
      const _self = this
      const params = {
        mobile: _self.phone_num,
        invitationCode: _self.code_num,
        areaCode: _self.areaCode,
        myCaptchaToken: _self.myCaptchaToken
      }
      sendNewByWeChat(params).then((res) => {
        if (Number(res.code) === 200) {
          _self.sTime()
        } else {
          Toast(res.data.messages)
          this.$router.replace({ path: '/' })
        }
      })
    },
    // 判断是否是老用户
    lisCheckUserRegister() {
      const _self = this
      const params = {
        mobile: _self.phone_num
      }
      isCheckUserRegister(params).then((res) => {
        if (Number(res.code) === 200) {
          _self.wait == 59
          _self.myCaptcha.show()
          _self.isDingxiang = true

          //   // 新用户跳转注册页面
          // if (res.data.status == 1) {
          //   _self.wait == 59;
          //   _self.myCaptcha.show();
          //   _self.isDingxiang = true;
          // } else {
          //   _self.$router.replace({
          //     path: "/register",
          //     query: {
          //       mobile: _self.phone_num,
          //       codeNumber: _self.codeNumber,
          //     },
          //   });
          // }
        }
      })
    },

    // 登录
    onSublime() {
      localStorage.clear()
      const _self = this
      const params = {
        mobile: _self.phone_num,
        code: _self.phone_code,
        openId: _self.openId,
        codeNumber: _self.codeNumber,
        type: 147
      }
      this.$store.dispatch('user/login', params).then((res) => {
        if (Number(res.code) === 200) {
          if (res.is_new === 1) {
            const obj = {
              // unionid: res.mobile,
              Inviter: _self.codeNumber ? _self.codeNumber : ''
            }

            this.$store
              .dispatch('user/reportAnalytics', setAnalytics(obj))
              .then((resd) => {
              })
          }

          _self.getUserInfo()
        }
      })
    },
    // 微信授权
    wxlogin(wxurl) {
      const _self = this
      if (_self.$route.query.code) {
        const params = {
          code: _self.$route.query.code,
          type: 147
        }

        getCodeByOpenid(params).then((res) => {
          if (Number(res.code) === 200) {
            const unionid = res.data.userInfo.unionid

            _self.openId = res.data.openid
            localStorage.setItem('SearchHistory', JSON.stringify([]))
            localStorage.setItem('mobile', res.data.mobile)
            const obj = {
              // unionid: res.mobile,
              mobile: res.data.mobile,
              openId: res.data.openid,
              unionid: unionid,
              Inviter: _self.codeNumber ? _self.codeNumber : ''
            }

            if (res.data.is_new === 1) {
              this.$store
                .dispatch('user/reportAnalytics', setAnalytics(obj))
                .then((resd) => {
                })
            }

            const body = {
              unionid: unionid,
              from: 147
            }
            getWapTokenByUnionid(body)
              .then((resd) => {
                // const _toast = Toast.loading({
                //   message: '自动登录中',
                //   forbidClick: true
                // })
                if (resd.code == 200) {
                  // this.$store.user.commit('SET_NICKNAME',resd.nickname)
                  this.$store.state.user.nickname = resd.nickname
                  this.$store.state.user.uid = resd.id
                  this.$store.state.user.token = resd.token
                  localStorage.setItem('SearchHistory', JSON.stringify([]))
                  localStorage.setItem('mobile', resd.mobile)
                  localStorage.setItem('uid', resd.id)
                  localStorage.setItem('token', resd.token)
                  localStorage.setItem('openid', res.data.openid)
                  localStorage.setItem('md5Id', resd.md5Id)
                  localStorage.setItem('recode', resd.recode)
                  localStorage.setItem('nickname', resd.nickname)
                  _self.getUserInfo(wxurl)
                  _self.getUserEquityType(resd.id) // get用户会员类型
                } else if (resd.code == 400) {
                  Toast.clear()
                  this.loginStatus = 1
                } else {
                  Toast.clear()
                  this.loginStatus = 1
                }
              })
              .catch(err => {
                console.log(err)
                Toast.clear()
                this.loginStatus = 1
              })
          }
        })
      } else {
        getWxAppId({ type: 147 }).then((res) => {
          if (Number(res.code) === 200) {
            const appid = res.appId
            const scope = 'snsapi_userinfo'
            const state = _self.codeNumber
            const url = _self.urlencode(window.location.href)

            window.location.href =
              'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
              appid +
              '&redirect_uri=' +
              url +
              '&response_type=code&scope=' +
              scope +
              '&state=' +
              state +
              '#wechat_redirect'
          }
        })
      }
    },
    // 重定向地址处理函数
    urlencode(str) {
      str = (str + '').toString()
      return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%20/g, '+')
    },
    // 截取微信url中code
    GetUrlParam(paraName) {
      var url = document.location.toString()
      var arrObj = url.split('?')
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split('&')
        var arr
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split('=')
          if (arr != null && arr[0] == paraName) {
            return arr[1]
          }
        }
        return ''
      } else {
        return ''
      }
    },
    GetUrlParam2(_url, paraName) {
      var url = _url.toString()
      var arrObj = url.split('?')
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split('&')
        var arr
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split('=')
          if (arr != null && arr[0] == paraName) {
            return arr[1]
          }
        }
        return ''
      } else {
        return ''
      }
    },
    // get用户会员类型
    getUserEquityType(uid) {
      getEquityType({
        mid: uid
      }).then((res) => {
        if (Number(res.code) === 200) {
          // 存储用户会员类型  0注册会员  1 金卡会员 2 白金会员 3 黑金会员
          window.localStorage.setItem('memberType', res.data.memberType)
        } else {
          Toast(res.msg)
        }
      })
    },
    getUserInfo(wxurl) {
      const _self = this
      this.$store.dispatch('user/getInfo', null).then((res) => {
        if (_self.$route.query.backUrl) {
          const backurl = decodeURIComponent(
            decodeURIComponent(_self.GetUrlParam('backUrl'))
          )
          window.location.replace(backurl)
          return
        }
        if (_self.$route.query.active == 'dalibao') {
          const url = 'https://web.yuetao.vip/dalibao?env=2'
          window.location.replace(url)
          return
        }
        if (_self.$route.query.active == 'gaode') {
          // const url = 'https://web.yuetao.vip/gaode'
          // window.location.replace(url);
          this.$router.replace({ path: '/gaode' })
          return
        }
        if (_self.$route.query.active == 'oil') { // 加油优惠券
          _self.$router.replace({ path: '/oilConponIndex' })
          return
        }

        if (_self.$route.query.code) {
          // 微信授权后，会在url中添加一个code码

          if (wxurl) {
            _self.$router.replace({ path: wxurl })
          } else {
            _self.$router.replace({ path: '/' })
          }

          return
        }
        _self.$router.go(-1)
        // });
      })
    }
  }
}
</script>

<style lang="less" scoped>
body {
  padding: 0 !important;
  background: #fff;
  height: 100%;
}

#app {
  height: 100%;

  overflow: hidden;
}

.login_content {
  width: 100%;
  height: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  overflow: hidden;
  background: #fff;
  -webkit-overflow-scrolling: unset;

  .login_banner {
    width: 80px;
    margin: 60px auto 30px auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  .login_box {
    overflow: auto;
    height: auto;
    margin: 0 40px;

    .put_box {
      background: #fff;

      .input_box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .icon_int {
          width: 25px;
          height: 25px;
          display: block;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .vCodeBtn {
          width: 100px;
          line-height: 35px;
          background: #ffffff;
          position: absolute;
          right: 0;
          top: 6px;
          z-index: 10;
        }
      }
    }
  }

  .brew_box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0, 0, 0);
    opacity: 0.5;
    z-index: 10;
  }
}

// /deep/ .van-field__control {
//   height: 50px;
//   font-size: 16px;
// }
// /deep/ .van-field__label {
//   width: auto;
// }
</style>
